import React from 'react'
import { StgOurProjectsProps } from './type'
import { Column, Container, Row } from '@/components/base/gridview'
import Image from '@/components/base/image'
import Anchor from '@/components/base/anchor'
import Icon from '@/components/base/icon'
import { functions, path, useApp } from '@wap-client/core'

const OurProjects: React.FC<StgOurProjectsProps> = ({
  pagetitle,
  background,
  link,
  items,
  ishome,
}) => {
  const app = useApp()
  return (
    <div
      className="stg-ourprojects"
      style={{
        background: `url(${path.asset(
          app.environment,
          background?.src
        )}) no-repeat center center`,
        backgroundSize: 'cover',
      }}
    >
      <Container size="extended" className="stg-ourprojects-container">
        <Row
          className="stg-ourprojects-row"
          xl={{ align: 'center', justify: 'between' }}
        >
          <Column className="stg-ourprojects-column">
            <div className="stg-ourprojects">
              <h3
                className={functions.classnames(
                  'stg-ourprojects-page-title',
                  ishome && 'ourprojects-home-title'
                )}
              >
                {pagetitle}
              </h3>
              <div className="stg-ourprojects-card">
                {items?.map((item, index) => (
                  <div className="stg-ourprojects-card-item" key={index}>
                    <Anchor {...item.detail}>
                      <div className="stg-ourprojects-card-item-image">
                        <Image {...item.image} alt="" />
                      </div>
                      <div className="stg-ourprojects-card-item-texts">
                        <div className="stg-ourprojects-card-item-texts-projectname">
                          {item.projectname}
                        </div>
                        <div className="stg-ourprojects-card-item-texts-location">
                          {item.location}
                        </div>
                      </div>
                    </Anchor>
                  </div>
                ))}
              </div>
            </div>
            {ishome && (
              <div className="link">
                <Anchor {...link} className="stg-ourteam-link">
                  {app.settings.translations['allprojects']}

                  <Icon name="arrow-right" />
                </Anchor>
              </div>
            )}
          </Column>
        </Row>
      </Container>
    </div>
  )
}

export default OurProjects
